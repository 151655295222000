/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";

import {
  dropdownMeasurementListItemElements,
  EDIT_KEY,
  IMAGE_KEY,
} from "../../../../../../constants/contextMenuEntries";

import { updateMeasurement } from "../../../../../../actions/measurements";

import CustomModal from "../../../../../../components/CustomModal";
import CustomImageView from "../../../../../../components/CustomImageView";
import ContextMenu from "../../../../../../containers/ContextMenu";
import EditReferencePointDialog from "../../../../../../containers/dialogs/EditReferencePointDialog";
import { pointNameLookupTable } from "../../../../../../constants/pointLookupTable";

import * as styles from "../../../../../../styles/styles";
import "./styles.css";

const propTypes = {
  classes: PropTypes.object,
  measurement: PropTypes.object,
  componentList: PropTypes.array,
  onListClick: PropTypes.func,
  onHoverListEnter: PropTypes.func,
  onHoverListLeave: PropTypes.func,
  measurmentListClick: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateMeasurement: PropTypes.func,
};

const overrideStyles = (theme) => ({
  root: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: styles.white,
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  root_select: {
    paddingTop: "0",
    paddingBottom: "0",
    paddingLeft: "0",
    margin: "0.5rem 1rem 0.5rem 1rem",
    width: "auto",
    backgroundColor: "rgba(89, 83, 210, 0.08)",
    boxShadow: styles.boxShadowListElement,
    "&:hover": {
      boxShadow: styles.boxShadowListElementHover,
    },
  },
  vertical: {
    marginRight: "2rem",
  },
});

const ReferenceListItem = ({
  measurement,
  componentList,
  onListClick,
  onHoverListEnter,
  onHoverListLeave,
  measurmentListClick,
  updateMeasurement,
  classes,
}) => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [menuEntryKey, setMenuEntryKey] = React.useState(null);
  const [selected, setSelected] = React.useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setMenuEntryKey(null);
  };

  const openModal = (menuEntryKey) => {
    const bool = typeof menuEntryKey === "string";
    setModalOpen(bool);
    setMenuEntryKey(bool ? menuEntryKey : false);
  };

  const onClick = () => {
    setSelected(true);
    onListClick(measurement);
  };

  const onClickImage = (event) => {
    event.stopPropagation();
    openModal(IMAGE_KEY);
  };

  const onClickMeasurementEdit = (newMeasurement) => {
    updateMeasurement({
      id: newMeasurement.id,
      id_user: newMeasurement.id_user,
      component: newMeasurement.component,
      depth: newMeasurement.depth,
      project_id: newMeasurement.project_id,
    });
    closeModal();
  };

  const created_at = moment.unix(measurement.created_at);
  const image = measurement.image.original;
  const id = measurement.id;

  const baseTitle = measurement.used_for_transformation
    ? t('Project.Map.MeasurementList.ReferenceListItem.txtPointTypeTransformationPoint', {ns: 'screens_private'})
    : pointNameLookupTable[measurement.type];
  const title = baseTitle + " - " + measurement.id_user || measurement.id;

  const isSelected = id === measurmentListClick

  return (
    <>
      {modalOpen &&
        renderModalContent(
          modalOpen,
          menuEntryKey,
          closeModal,
          onClickMeasurementEdit,
          measurement,
          componentList
        )}
      <ListItem
        onMouseLeave={() => onHoverListLeave(id, measurement.type)}
        onMouseEnter={() => onHoverListEnter(id, measurement.type)}
        onMouseOver={() => onHoverListEnter(id, measurement.type)}
        classes={{ root: isSelected ? classes.root_select : classes.root }}
        className="measurment-list-item-list-item"
        onClick={onClick}
      >
        <img
          onClick={onClickImage}
          src={image}
          className="measurement-list-item-image-thumbnail"
          alt={`${measurement.type} Bild`}
        />
        <Divider
          classes={{ vertical: classes.vertical }}
          orientation={"vertical"}
        />
        <ListItemText
          style={{ flex: 8 }}
          primary={title}
          secondary={created_at.format("dd. DD.MM.YYYY HH:mm:ss")}
        />
        <ListItemText
          primary={t('Project.Map.MeasurementList.ReferenceListItem.txtComponent', {ns: 'screens_private'})}
          secondary={
            measurement.component !== null
              ? measurement.component
              : t('Project.Map.MeasurementList.ReferenceListItem.txtNoComponentSet', {ns: 'screens_private'})
          }
        />
        <ListItemSecondaryAction className="measurement-list-item-secondary-action">
          <ContextMenu
            color={styles.darkBlueBase}
            contextMenuEntries={dropdownMeasurementListItemElements}
            onMenuEntryClicked={(menuEntryKey) =>
              handleOnMenuEntryClicked(menuEntryKey, openModal)
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

function handleOnMenuEntryClicked(menuEntryKey, openModal) {
  openModal(menuEntryKey);
}

function renderModalContent(
  modalOpen,
  menuEntryKey,
  closeModal,
  onClickMeasurementEdit,
  measurement,
  componentList
) {
  switch (menuEntryKey) {
    case EDIT_KEY:
      return (
        <EditReferencePointDialog
          measurement={measurement}
          componentList={componentList}
          modalOpen={modalOpen}
          onNegativeButtonClicked={() => closeModal()}
          onPositiveButtonClicked={onClickMeasurementEdit}
        />
      );
    case IMAGE_KEY:
      return (
        <CustomModal
          showCloseButton={true}
          open={modalOpen}
          image={measurement.image.original}
          onClose={() => closeModal()}
          render={() => (
            <CustomImageView>
              <img
                src={measurement.image.original}
                className="image-modal-image"
                alt={`Bild`}
              />
            </CustomImageView>
          )}
        />
      );
    default:
      return <div></div>;
  }
}

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateMeasurement,
    },
    dispatch
  );
}

ReferenceListItem.propTypes = propTypes;
export default connect(
  null,
  dispatchToProps
)(withStyles(overrideStyles, { withTheme: true })(ReferenceListItem));
