/* eslint-disable no-unused-vars */
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import * as styles from "../../../../../../styles/styles";

const propTypes = {
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  listItemIndex: PropTypes.any,
  listItemDisabled: PropTypes.bool,
  listItemDownloadLink: PropTypes.any,
  listItemDownloadFile: PropTypes.any,
  listItemDownloadFileName: PropTypes.string,
  listItemDownloadFileExtension: PropTypes.string,
  downloadAllFiles: PropTypes.bool,
  onListItemClicked: PropTypes.func,
};

const SingleActionDownloadItem = ({
  primaryText,
  secondaryText,
  listItemIndex,
  listItemDisabled,
  listItemDownloadLink,
  listItemDownloadFile,
  listItemDownloadFileName,
  listItemDownloadFileExtension,
  downloadAllFiles,
  onListItemClicked
}) => {
  const [stateOnEdit, setOnEdit] = useState(true);
  const [stateFileName, setFileName] = useState(listItemDownloadFileName);

  React.useEffect(() => {
    if (downloadAllFiles && !listItemDisabled) {
      onDownloadClicked()
    }  
  }, [downloadAllFiles])

  const onChangeFileName = (event) => {
    const newFileName = event.target.value;
    setFileName(newFileName);
  };

  const onButtonClicked = () => {
    setOnEdit(!stateOnEdit);
  };

  const onDownloadClicked = () => {
    fetch(listItemDownloadLink)
      .then((res) => res.blob()) // Gets the response and returns it as a blob
      .then((blob) => {
        saveAs(blob, `${stateFileName}.${listItemDownloadFileExtension}`);
      });
    onListItemClicked(listItemDownloadFile);
  };

  const renderEnabledListItem = (listItemIndex, listItemDownloadFile) => {
    return (
      <ListItem key={listItemIndex + "_" + listItemDownloadFile} style={{paddingLeft: 0}}>
        <Grid container size={12} sx={{flexGrow: 1}}>
          <Grid container size={8}>
            <Grid size={12}>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </Grid>
            <Grid size={12}>
              <TextField
                variant="standard"
                disabled={stateOnEdit}
                value={stateFileName}
                onChange={onChangeFileName}
                fullWidth={true}
                multiline={false}
              />
            </Grid>
          </Grid>
          <Grid
            container
            size={4}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid size={4}>
              <IconButton
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                onClick={() => onButtonClicked()}
                size="large"
              >
                {stateOnEdit ? (
                  <EditIcon fontSize="small" />
                ) : (
                  <CheckIcon fontSize="small" />
                )}
              </IconButton>
            </Grid>
            <Grid size={4}>
              <IconButton
                style={{
                  backgroundColor: styles.darkBlueSuperLight,
                  marginRight: styles.spacing16,
                }}
                onClick={() => onDownloadClicked()}
                size="large"
              >
                <DownloadIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const renderDisabledListItem = (listItemIndex, listItemDownloadFile) => {
    return (
      <ListItemButton
        key={listItemIndex + "_" + listItemDownloadFile}
        disabled={true}
        style={{paddingLeft: 0}}
      >
        <Grid container size={12}>
          <Grid container size={8}>
            <Grid size={12}>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </Grid>
            <Grid size={12}>
              <div />
            </Grid>
          </Grid>
          <Grid
            container
            size={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <Grid size={4}>
              <div />
            </Grid>
            <Grid size={4}>
              <div />
            </Grid>
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };

  return (
    <>
      {listItemDisabled
        ? renderDisabledListItem(listItemIndex, listItemDownloadFile)
        : renderEnabledListItem(listItemIndex, listItemDownloadFile)}
    </>
  );
};

SingleActionDownloadItem.propTypes = propTypes;
export default SingleActionDownloadItem;
