import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Column from "../../layout/Column";
import ButtonGroup from "../../ButtonGroup";
import EditUserProfile from "../EditUserProfile";
import UserAvatar from "../UserAvatar";

import "./styles.css";

const propTypes = {
  avatarTooLarge: PropTypes.bool,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  firstNameValid: PropTypes.bool,
  lastNameValid: PropTypes.bool,
  avatar: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  editUser: PropTypes.bool,
  email: PropTypes.string,
  cancel: PropTypes.func,
  editUserDetails: PropTypes.func,
  updateEditUserFlag: PropTypes.func,
  onChangeName: PropTypes.func,
  createAvatarUrl: PropTypes.func,
  onChangeAvatar: PropTypes.func,
  renderSnackbar: PropTypes.func
};
// this.setState({ editUser: true })
const DisplayUserProfile = ({
  avatar,
  avatarTooLarge,
  firstName,
  firstNameValid,
  lastName,
  lastNameValid,
  editUser,
  email,
  cancel,
  editUserDetails,
  updateEditUserFlag,
  onChangeName,
  createAvatarUrl,
  onChangeAvatar,
  renderSnackbar
}) => {
  const { t } = useTranslation();

  const renderDetails = () => {
    if (editUser)
      return (
        <EditUserProfile
          firstName={firstName}
          lastName={lastName}
          onChangeFirstName={(event) => onChangeName("firstName", event)}
          onChangeLastName={(event) => onChangeName("lastName", event)}
        />
      );
    return (
      <div className="user-profile-name-surname-container">
        <div className="display-user-profile-name-wrapper">
          <Typography variant="body1">{firstName}</Typography>
          <Typography variant="body1">&nbsp;{lastName}</Typography>
        </div>
        <Typography variant="body1">{email}</Typography>
      </div>
    );
  };

  const renderButtons = () => {
    if (editUser)
      return (
        <ButtonGroup
          containerStyles={{ marginTop: "auto" }}
          align="right"
          leftButtonText={t('button.btnCancel', {ns: 'common'})}
          rightButtonText={t('button.btnSave', {ns: 'common'})}
          leftButtonClick={() => cancel()}
          rightButtonClick={() => editUserDetails()}
          rightButtonDisabled={false}
          leftButtonDisabled={!(firstNameValid && lastNameValid)}
        />
      );
    return (
      <Button
        size="medium"
        style={{ margin: "auto 0 0 auto" }}
        variant="contained"
        color="primary"
        onClick={() => updateEditUserFlag(true)}
      >
        {t('button.btnEdit', {ns: 'common'})}
      </Button>
    );
  };

  return (
    <>
      <Column side="left" flexWrap="wrap">
        <UserAvatar
          avatar={createAvatarUrl(avatar)}
          avatarTooLarge={avatarTooLarge}
          editAvatar={editUser}
          firstName={firstName.length > 0 ? firstName : "?"}
          lastName={lastName.length > 0 ? lastName : "?"}
          onChangeAvatar={(avatar, avatarTooLarge) => onChangeAvatar(avatar, avatarTooLarge)}
          renderSnackbar={renderSnackbar}
        />
      </Column>
      <Column side="right" flexDirection="column">
        {renderDetails()}
      </Column>
      {renderButtons()}
    </>
  );
};

DisplayUserProfile.propTypes = propTypes;
export default DisplayUserProfile;
