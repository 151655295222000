import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetSnackbar } from "../../actions/snackbars";
import CustomSnackbar from "../../components/CustomSnackbar";

import {
  // PROJECT
  assignProjectSuccess,
  assignProjectFail,
  createProjectFail,
  createProjectSuccess,
  createProjectFailOnlyOneProjectAllowed,
  createProjectFailJobOrderAlreadyUsed,
  deleteProjectsFail,
  resetProjectFail,
  resetProjectSuccess,
  downloadProjectsFail,
  updateProjectFail,
  updateProjectSuccess,
  deleteProjectSuccess,
  deleteProjectFail,

  // USER
  deleteAvatarFail,
  deleteAvatarSuccess,
  deleteInvitationFail,
  deleteInvitationSuccess,
  editUserDetailsFail,
  editUserDetailsSuccess,
  inviteUserFail,
  inviteUserSuccess,
  removeUserFail,
  removeUserSuccess,
  resendInvitationFail,
  resendInvitationSuccess,
  uploadAvatarFail,
  uploadAvatarSuccess,
  requestAccountDeletionSuccess,
  requestAccountDeletionFail,
  confirmAccountDeletionSuccess,
  confirmAccountDeletionFail,

  // MEASUREMENT
  updateMeasurementFail,
  updateMeasurementSuccess,

  // MEASUREMENT_LINE
  updateMeasurementLineFail,
  updateMeasurementLineSuccess,

  // MEASUREMENT_SEGMENT

  // VOLUME
  deleteVolumeFail,
  deleteVolumeSuccess,
  updateVolumeFail,
  updateVolumeSuccess,

  // TOPOGRAPHIC POINT
  updateTopographicPointSuccess,
  updateTopographicPointFail,

  // MEASURE_LAYER_CONFIG
  createMeasureLayerConfigSuccess,
  createMeasureLayerConfigFail,
  updateMeasureLayerConfigFail,
  updateMeasureLayerConfigSuccess,
  deleteMeasureLayerConfigFail,
  deleteMeasureLayerConfigSuccess,

  // MEDIA_FILE
  deleteMediaFileFail,
  deleteMediaFileSuccess,

  // PROJECT_FILE
  createProjectFileSuccess,
  createProjectFileFail,
  deleteProjectFileSuccess,
  deleteProjectFileFail,
  projectFileIsToLargeFail,
  projectFileExtensionNotSupportedFail,
  createProjectFileAlreadyUploadedFail,

  // MAP_LAYER
  createMapLayerSuccess,
  createMapLayerFail,
  updateMapLayerSuccess,
  updateMapLayerFail,
  deleteMapLayerSuccess,
  deleteMapLayerFail,

  // MEASURE_LAYER_CONFIG_TEMPLATES
  copyMeasureLayerConfigTemplateSuccess,
  copyMeasureLayerConfigTemplateFail,

  // COMPONENT_CATALOGS
  copyComponentCatalogSuccess,
  copyComponentCatalogFail,

  // USER_AVATAR
  userAvatarFileIsToLargeFail,
  userAvatarFileExtensionNotSupportedFail
} from "../../constants/snackbars";

const propTypes = {
  resetSnackbar: PropTypes.func,
  snackbarFlags: PropTypes.object,
};

const ShowSnackbar = ({ resetSnackbar, snackbarFlags }) => {
  const { t } = useTranslation();

  const allTrueSnackbarFlags = Object.keys(snackbarFlags).filter(
    (key) => snackbarFlags[key]
  );
  const snackbarTexts = {
    // PROJECT
    [updateProjectFail]: t('ShowSnackbar.txtUpdateProjectFail', {ns: 'containers'}),
    [updateProjectSuccess]: t('ShowSnackbar.txtUpdateProjectSuccess', {ns: 'containers'}),
    [assignProjectSuccess]: t('ShowSnackbar.txtAssignProjectSuccess', {ns: 'containers'}),
    [assignProjectFail]: t('ShowSnackbar.txtAssignProjectFail', {ns: 'containers'}),
    [deleteProjectsFail]: t('ShowSnackbar.txtDeleteProjectsFail', {ns: 'containers'}),
    [downloadProjectsFail]: t('ShowSnackbar.txtDownloadProjectsFail', {ns: 'containers'}),
    [createProjectFail]: t('ShowSnackbar.txtCreateProjectFail', {ns: 'containers'}),
    [createProjectSuccess]: t('ShowSnackbar.txtCreateProjectSuccess', {ns: 'containers'}),
    [createProjectFailOnlyOneProjectAllowed]: t('ShowSnackbar.txtCreateProjectFailOnlyOneProjectAllowed', {ns: 'containers'}),
    [createProjectFailJobOrderAlreadyUsed]: t('ShowSnackbar.txtCreateProjectFailJobOrderAlreadyUsed', {ns: 'containers'}),
    [resetProjectFail]: t('ShowSnackbar.txtResetProjectFail', {ns: 'containers'}),
    [resetProjectSuccess]: t('ShowSnackbar.txtResetProjectSuccess', {ns: 'containers'}),
    [deleteProjectSuccess]: t('ShowSnackbar.txtDeleteProjectSuccess', {ns: 'containers'}),
    [deleteProjectFail]: t('ShowSnackbar.txtDeleteProjectFail', {ns: 'containers'}),

    // USER
    [inviteUserFail]: t('ShowSnackbar.txtInviteUserFail', {ns: 'containers'}),
    [inviteUserSuccess]: t('ShowSnackbar.txtInviteUserSuccess', {ns: 'containers'}),
    [deleteInvitationFail]: t('ShowSnackbar.txtDeleteInvitationFail', {ns: 'containers'}),
    [deleteInvitationSuccess]: t('ShowSnackbar.txtDeleteInvitationSuccess', {ns: 'containers'}),
    [resendInvitationFail]: t('ShowSnackbar.txtResendInvitationFail', {ns: 'containers'}),
    [resendInvitationSuccess]: t('ShowSnackbar.txtResendInvitationSuccess', {ns: 'containers'}),
    [removeUserFail]: t('ShowSnackbar.txtRemoveUserFail', {ns: 'containers'}),
    [removeUserSuccess]: t('ShowSnackbar.txtRemoveUserSuccess', {ns: 'containers'}),
    [deleteAvatarFail]: t('ShowSnackbar.txtDeleteAvatarFail', {ns: 'containers'}),
    [deleteAvatarSuccess]: t('ShowSnackbar.txtDeleteAvatarSuccess', {ns: 'containers'}),
    [uploadAvatarSuccess]: t('ShowSnackbar.txtUploadAvatarSuccess', {ns: 'containers'}),
    [uploadAvatarFail]: t('ShowSnackbar.txtUploadAvatarFail', {ns: 'containers'}),
    [editUserDetailsSuccess]: t('ShowSnackbar.txtEditUserDetailsSuccess', {ns: 'containers'}),
    [editUserDetailsFail]: t('ShowSnackbar.txtEditUserDetailsFail', {ns: 'containers'}),
    [requestAccountDeletionSuccess]: t('ShowSnackbar.txtRequestAccountDeletionSuccess', {ns: 'containers'}),
    [requestAccountDeletionFail]: t('ShowSnackbar.txtRequestAccountDeletionFail', {ns: 'containers'}),
    [confirmAccountDeletionSuccess]: t('ShowSnackbar.txtConfirmAccountDeletionSuccess', {ns: 'containers'}),
    [confirmAccountDeletionFail]: t('ShowSnackbar.txtConfirmAccountDeletionFail', {ns: 'containers'}),

    // MEASUREMENT
    [updateMeasurementFail]: t('ShowSnackbar.txtUpdateMeasurementFail', {ns: 'containers'}),
    [updateMeasurementSuccess]: t('ShowSnackbar.txtUpdateMeasurementSuccess', {ns: 'containers'}),

    // MEASUREMENT_LINE
    [updateMeasurementLineFail]: t('ShowSnackbar.txtuUpdateMeasurementLineFail', {ns: 'containers'}),
    [updateMeasurementLineSuccess]: t('ShowSnackbar.txtUpdateMeasurementLineSuccess', {ns: 'containers'}),

    // MEASUREMENT_SEGMENT

    // VOLUME
    [deleteVolumeFail]: t('ShowSnackbar.txtDeleteVolumeFail', {ns: 'containers'}),
    [deleteVolumeSuccess]: t('ShowSnackbar.txtDeleteVolumeSuccess', {ns: 'containers'}),
    [updateVolumeFail]: t('ShowSnackbar.txtUpdateVolumeFail', {ns: 'containers'}),
    [updateVolumeSuccess]: t('ShowSnackbar.txtUpdateVolumeSuccess', {ns: 'containers'}),

    // TOPOGRAPHIC POINT
    [updateTopographicPointSuccess]: t('ShowSnackbar.txtUpdateTopographicPointSuccess', {ns: 'containers'}),
    [updateTopographicPointFail]: t('ShowSnackbar.txtUpdateTopographicPointFail', {ns: 'containers'}),

    // MEASURE_LAYER_CONFIG
    [createMeasureLayerConfigSuccess]: t('ShowSnackbar.txtCreateMeasureLayerConfigSuccess', {ns: 'containers'}),
    [createMeasureLayerConfigFail]: t('ShowSnackbar.txtCreateMeasureLayerConfigFail', {ns: 'containers'}),
    [updateMeasureLayerConfigFail]: t('ShowSnackbar.txtUpdateMeasureLayerConfigFail', {ns: 'containers'}),
    [updateMeasureLayerConfigSuccess]: t('ShowSnackbar.txtUpdateMeasureLayerConfigSuccess', {ns: 'containers'}),
    [deleteMeasureLayerConfigFail]: t('ShowSnackbar.txtDeleteMeasureLayerConfigFail', {ns: 'containers'}),
    [deleteMeasureLayerConfigSuccess]: t('ShowSnackbar.txtDeleteMeasureLayerConfigSuccess', {ns: 'containers'}),

    // MEDIA_FILE
    [deleteMediaFileFail]: t('ShowSnackbar.txtDeleteMediaFileFail', {ns: 'containers'}),
    [deleteMediaFileSuccess]: t('ShowSnackbar.txtDeleteMediaFileSuccess', {ns: 'containers'}),

    // PROJECT_FILE
    [createProjectFileSuccess]: t('ShowSnackbar.txtCreateProjectFileSuccess', {ns: 'containers'}),
    [createProjectFileFail]: t('ShowSnackbar.txtCreateProjectFileFail', {ns: 'containers'}),
    [deleteProjectFileSuccess]: t('ShowSnackbar.txtDeleteProjectFileSuccess', {ns: 'containers'}),
    [deleteProjectFileFail]: t('ShowSnackbar.txtDeleteProjectFileFail', {ns: 'containers'}),
    [projectFileIsToLargeFail]: t('ShowSnackbar.txtProjectFileIsToLargeFail', {ns: 'containers'}),
    [projectFileExtensionNotSupportedFail]: t('ShowSnackbar.txtProjectFileExtensionNotSupportedFail', {ns: 'containers'}),
    [createProjectFileAlreadyUploadedFail]: t('ShowSnackbar.txtCreateProjectFileAlreadyUploadedFail', {ns: 'containers'}),

    // MAP_LAYER
    [createMapLayerSuccess]: t('ShowSnackbar.txtCreateMapLayerSuccess', {ns: 'containers'}),
    [createMapLayerFail]: t('ShowSnackbar.txtCreateMapLayerFail', {ns: 'containers'}),
    [updateMapLayerSuccess]: t('ShowSnackbar.txtUpdateMapLayerSuccess', {ns: 'containers'}),
    [updateMapLayerFail]: t('ShowSnackbar.txtUpdateMapLayerFail', {ns: 'containers'}),
    [deleteMapLayerSuccess]: t('ShowSnackbar.txtDeleteMapLayerSuccess', {ns: 'containers'}),
    [deleteMapLayerFail]: t('ShowSnackbar.txtDeleteMapLayerFail', {ns: 'containers'}),

    // MEASURE_LAYER_CONFIG_TEMPLATES
    [copyMeasureLayerConfigTemplateSuccess]: t('ShowSnackbar.txtCopyMeasureLayerConfigTemplateSuccess', {ns: 'containers'}),
    [copyMeasureLayerConfigTemplateFail]: t('ShowSnackbar.txtCopyMeasureLayerConfigTemplateFail', {ns: 'containers'}),

    // COMPONENT_CATALOGS
    [copyComponentCatalogSuccess]: t('ShowSnackbar.txtCopyComponentCatalogSuccess', {ns: 'containers'}),
    [copyComponentCatalogFail]: t('ShowSnackbar.txtCopyComponentCatalogFail', {ns: 'containers'}),

    // USER_AVATAR
    [userAvatarFileIsToLargeFail]: t('ShowSnackbar.txtUserAvatarFileIsToLargeFail', {ns: 'containers'}),
    [userAvatarFileExtensionNotSupportedFail]: t('ShowSnackbar.txtUserAvatarFileExtensionNotSupportedFail', {ns: 'containers'}),
  };

  return allTrueSnackbarFlags.map((snackbarFlag, index) => {
    return (
      <CustomSnackbar
        key={index}
        variant={snackbarFlag.includes("Fail") ? "warning" : "success"}
        message={snackbarTexts[snackbarFlag]}
        open={snackbarFlags[snackbarFlag]}
        onClose={() => resetSnackbar(snackbarFlag)}
      />
    );
  });
};

function dispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetSnackbar,
    },
    dispatch
  );
}

function stateToProps(state) {
  const snackbars = state.get("snackbars");
  return {
    snackbarFlags: {
      // PROJECT
      [createProjectFail]: snackbars.get(createProjectFail),
      [createProjectSuccess]: snackbars.get(createProjectSuccess),
      [createProjectFailOnlyOneProjectAllowed]: snackbars.get(
        createProjectFailOnlyOneProjectAllowed
      ),
      [createProjectFailJobOrderAlreadyUsed]: snackbars.get(
        createProjectFailJobOrderAlreadyUsed
      ),
      [deleteProjectsFail]: snackbars.get(deleteProjectsFail),
      [downloadProjectsFail]: snackbars.get(downloadProjectsFail),
      [updateProjectFail]: snackbars.get(updateProjectFail),
      [updateProjectSuccess]: snackbars.get(updateProjectSuccess),
      [assignProjectSuccess]: snackbars.get(assignProjectSuccess),
      [assignProjectFail]: snackbars.get(assignProjectFail),
      [resetProjectFail]: snackbars.get(resetProjectFail),
      [resetProjectSuccess]: snackbars.get(resetProjectSuccess),
      [deleteProjectSuccess]: snackbars.get(deleteProjectSuccess),
      [deleteProjectFail]: snackbars.get(deleteProjectFail),

      // USER
      [inviteUserFail]: snackbars.get(inviteUserFail),
      [inviteUserSuccess]: snackbars.get(inviteUserSuccess),
      [deleteInvitationFail]: snackbars.get(deleteInvitationFail),
      [deleteInvitationSuccess]: snackbars.get(deleteInvitationSuccess),
      [resendInvitationFail]: snackbars.get(resendInvitationFail),
      [resendInvitationSuccess]: snackbars.get(resendInvitationSuccess),
      [removeUserFail]: snackbars.get(removeUserFail),
      [removeUserSuccess]: snackbars.get(removeUserSuccess),
      [deleteAvatarSuccess]: snackbars.get(deleteAvatarSuccess),
      [deleteAvatarFail]: snackbars.get(deleteAvatarFail),
      [uploadAvatarFail]: snackbars.get(uploadAvatarFail),
      [uploadAvatarSuccess]: snackbars.get(uploadAvatarSuccess),
      [editUserDetailsFail]: snackbars.get(editUserDetailsFail),
      [editUserDetailsSuccess]: snackbars.get(editUserDetailsSuccess),
      [requestAccountDeletionSuccess]: snackbars.get(
        requestAccountDeletionSuccess
      ),
      [requestAccountDeletionFail]: snackbars.get(requestAccountDeletionFail),
      [confirmAccountDeletionSuccess]: snackbars.get(
        confirmAccountDeletionSuccess
      ),
      [confirmAccountDeletionFail]: snackbars.get(confirmAccountDeletionFail),

      // MEASUREMENT
      [updateMeasurementFail]: snackbars.get(updateMeasurementFail),
      [updateMeasurementSuccess]: snackbars.get(updateMeasurementSuccess),

      // MEASUREMENT_LINE
      [updateMeasurementLineFail]: snackbars.get(updateMeasurementLineFail),
      [updateMeasurementLineSuccess]: snackbars.get(
        updateMeasurementLineSuccess
      ),

      // MEASUREMENT_SEGMENT

      // VOLUME
      [deleteVolumeFail]: snackbars.get(deleteVolumeFail),
      [deleteVolumeSuccess]: snackbars.get(deleteVolumeSuccess),
      [updateVolumeFail]: snackbars.get(updateVolumeFail),
      [updateVolumeSuccess]: snackbars.get(updateVolumeSuccess),

      // TOPOGRAPHIC POINT
      [updateTopographicPointSuccess]: snackbars.get(
        updateTopographicPointSuccess
      ),
      [updateTopographicPointFail]: snackbars.get(updateTopographicPointFail),

      // MEASURE_LAYER_CONFIG
      [createMeasureLayerConfigSuccess]: snackbars.get(
        createMeasureLayerConfigSuccess
      ),
      [createMeasureLayerConfigFail]: snackbars.get(
        createMeasureLayerConfigFail
      ),
      [updateMeasureLayerConfigFail]: snackbars.get(
        updateMeasureLayerConfigFail
      ),
      [updateMeasureLayerConfigSuccess]: snackbars.get(
        updateMeasureLayerConfigSuccess
      ),
      [deleteMeasureLayerConfigFail]: snackbars.get(
        deleteMeasureLayerConfigFail
      ),
      [deleteMeasureLayerConfigSuccess]: snackbars.get(
        deleteMeasureLayerConfigSuccess
      ),

      // MEDIA_FILE
      [deleteMediaFileFail]: snackbars.get(deleteMediaFileFail),
      [deleteMediaFileSuccess]: snackbars.get(deleteMediaFileSuccess),

      // PROJECT_FILE
      [createProjectFileSuccess]: snackbars.get(createProjectFileSuccess),
      [createProjectFileFail]: snackbars.get(createProjectFileFail),
      [deleteProjectFileSuccess]: snackbars.get(deleteProjectFileSuccess),
      [deleteProjectFileFail]: snackbars.get(deleteProjectFileFail),
      [projectFileIsToLargeFail]: snackbars.get(projectFileIsToLargeFail),
      [projectFileExtensionNotSupportedFail]: snackbars.get(
        projectFileExtensionNotSupportedFail
      ),
      [createProjectFileAlreadyUploadedFail]: snackbars.get(
        createProjectFileAlreadyUploadedFail
      ),

      // MAP_LAYER
      [createMapLayerSuccess]: snackbars.get(createMapLayerSuccess),
      [createMapLayerFail]: snackbars.get(createMapLayerFail),
      [updateMapLayerSuccess]: snackbars.get(updateMapLayerSuccess),
      [updateMapLayerFail]: snackbars.get(updateMapLayerFail),
      [deleteMapLayerSuccess]: snackbars.get(deleteMapLayerSuccess),
      [deleteMapLayerFail]: snackbars.get(deleteMapLayerFail),

      // MEASURE_LAYER_CONFIG_TEMPLATES
      [copyMeasureLayerConfigTemplateSuccess]: snackbars.get(copyMeasureLayerConfigTemplateSuccess),
      [copyMeasureLayerConfigTemplateFail]: snackbars.get(copyMeasureLayerConfigTemplateFail),

      // COMPONENT_CATALOGS
      [copyComponentCatalogSuccess]: snackbars.get(copyComponentCatalogSuccess),
      [copyComponentCatalogFail]: snackbars.get(copyComponentCatalogFail),

      // USER_AVATAR
      [userAvatarFileIsToLargeFail]: snackbars.get(userAvatarFileIsToLargeFail),
      [userAvatarFileExtensionNotSupportedFail]: snackbars.get(userAvatarFileExtensionNotSupportedFail),
    },
  };
}

ShowSnackbar.propTypes = propTypes;
export default connect(stateToProps, dispatchToProps)(ShowSnackbar);
